// import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-scroll";
// Images
import logo from "../../assets/images/logo/appyhighF.svg";
import logoIcon from "../../assets/images/logo/logo-icon-dark.svg";

import playstore from "../../assets/icons/playstore.png";
import linkedin from "../../assets/icons/social/linkedin.svg";
import instagram from "../../assets/icons/social/instagram.svg";
import medium from "../../assets/icons/social/medium.png";

// Styles
import "./styles.scss";

function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <div className={`footer-style ${props.classContainer}`}>
      <div className="mobile-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="links-column">
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus"
                  rel="noreferrer"
                  target="_blank"
                >
                  About Us
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Products
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment"
                  rel="noreferrer"
                  target="_blank"
                >
                  Investment
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/careers"
                  rel="noreferrer"
                  target="_blank"
                >
                  Careers
                </a>
              </div>
            </div>
            <div className="logo-mobile">
              <a href="https://in.appyhigh.com" target="_blank" rel="noreferrer">
                {" "}
                <img src={logoIcon} alt="logo" />
              </a>
            </div>
            <div className="col-sm-12">
              <div className="about-company">
                An internet tech company the builds global products to engage &
                inspiring millions
              </div>
            </div>
            <div className="col-sm-12">
              <div className="social-links">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="link-item playstore"
                  href="https://play.google.com/store/apps/dev?id=8696216234951434473"
                >
                  <img src={playstore} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.linkedin.com/company/appyhigh/"
                >
                  <img src={linkedin} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://www.instagram.com/iamappyhigh/"
                >
                  <img src={instagram} alt="icon" />
                </a>
                <a
                  className="link-item"
                  rel="noreferrer"
                  target="_blank"
                  href="https://medium.com/appyhigh-technology-blog"
                >
                  <img src={medium} alt="icon" />
                </a>
              </div>

              <div className="copyright-mob">
                © {year}. All rights reserved. AppyHigh
              </div>
              <div className="copyright-mob">
                <a
                  href="https://in.appyhigh.com/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </div>
              <div className="copyright-mob">
                <a
                  href="https://in.appyhigh.com/media"
                  rel="noreferrer"
                  target="_blank"
                >
                  Media Kit
                </a>
              </div>
              {/* <div className="other-links mt--15">
                <HashLink className="link-item">Privacy Policy</HashLink>
                <HashLink className="link-item">Terms & Conditions</HashLink>
                <HashLink className="link-item">Site map</HashLink>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="desktop-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-4">
              <a href="https://in.appyhigh.com" target="_blank" rel="noreferrer">
                {" "}
                <img src={logo} className="logoicon" alt="logo" />
              </a>
              <div className="about-text">
                An internet tech company the builds global products to engage &
                inspiring millions.
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <a
                  className="link-item item-head"
                  href="https://in.appyhigh.com/aboutus"
                  target="_blank"
                  rel="noreferrer"
                >
                  About
                </a>
              </div>
              <div className="links">
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus#visionsec"
                  target="_blank"
                  rel="noreferrer"
                >
                  Values
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus#Anatomy"
                  target="_blank"
                  rel="noreferrer"
                >
                  Logo
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus#Combinedsec"
                  target="_blank"
                  rel="noreferrer"
                >
                  Ecosystem
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus#Storyboard"
                  target="_blank"
                  rel="noreferrer"
                >
                  Journey
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/aboutus#teamAbout"
                  target="_blank"
                  rel="noreferrer"
                >
                  Team
                </a>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <a
                  className="link-item item-head"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Product
                </a>
              </div>
              <div className="links">
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Strategy
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  SuperApps
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Utility
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Content
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/categories/all-products"
                  rel="noreferrer"
                  target="_blank"
                >
                  Creators
                </a>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <a
                  className="link-item item-head"
                  href="https://in.appyhigh.com/investment"
                  rel="noreferrer"
                  target="_blank"
                >
                  Investment
                </a>
              </div>
              <div className="links">
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment#thesis"
                  rel="noreferrer"
                  target="_blank"
                >
                  Thesis
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment#portfolio"
                  rel="noreferrer"
                  target="_blank"
                >
                  Portfolio
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment#philosophy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Philosophy
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment#process"
                  rel="noreferrer"
                  target="_blank"
                >
                  Process
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/investment#partners"
                  rel="noreferrer"
                  target="_blank"
                >
                  Partners
                </a>
              </div>
            </div>
            <div className="col-sm-2">
              <div className="title">
                <a
                  className="link-item item-head"
                  href="https://in.appyhigh.com/careers"
                  rel="noreferrer"
                  target="_blank"
                >
                  Careers
                </a>
              </div>

              <div className="links">
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/careers#culture"
                  rel="noreferrer"
                  target="_blank"
                >
                  Culture
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/careers#openingsParent"
                  rel="noreferrer"
                  target="_blank"
                >
                  Openings
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/careers#pillars"
                  rel="noreferrer"
                  target="_blank"
                >
                  Pillars
                </a>
                <a
                  className="link-item"
                  href="https://in.appyhigh.com/developer-form"
                  rel="noreferrer"
                  target="_blank"
                >
                  For Developers
                </a>
              </div>
            </div>
          </div>

          <div className="row mt--65">
            <div className="col-sm-6">
              <div className="copyright">
                ©{year}. all rights reserved. Appyhigh
              </div>
              <div className="two-things">
                <a
                  href="https://in.appyhigh.com/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                &nbsp;&nbsp;
                <a
                  href="https://in.appyhigh.com/media"
                  rel="noreferrer"
                  target="_blank"
                >
                  Media Kit
                </a>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="social-links">
                <a
                  href="https://www.linkedin.com/company/appyhigh/"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={linkedin} alt="icon" />
                </a>
                <a
                  href="https://www.instagram.com/iamappyhigh/"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={instagram} alt="icon" />
                </a>
                <a
                  href="https://medium.com/appyhigh-technology-blog"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={medium} alt="icon" />
                </a>
                <a
                  href="https://play.google.com/store/apps/dev?id=8696216234951434473"
                  rel="noreferrer"
                  target="_blank"
                  className="link-item"
                >
                  <img src={playstore} alt="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
