import { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

class GoogleAnalytics extends PureComponent {
  componentDidMount() {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate({ location, history }) {
    console.log("Component updated");
    // eslint-disable-next-line react/destructuring-assignment
    if (location.pathname === this.props.location.pathname) {
      return;
    }
    if (history.action === "PUSH") {
      console.log("History Pushed");
      console.log(window.location.pathname);
      ReactGA.pageview(window.location.pathname);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
