import React from "react";

import "./our-portfolio.scss";

// Clients Image
import logoImage1 from "../../../assets/images/investment-page/clients/Portfolio_img_1.png";
import logoImage2 from "../../../assets/images/investment-page/clients/Portfolio_img_2.png";
import logoImage3 from "../../../assets/images/investment-page/clients/Portfolio_img_3.png";
import logoImage4 from "../../../assets/images/investment-page/clients/Portfolio_img_4.png";
import logoImage5 from "../../../assets/images/investment-page/clients/Portfolio_img_5.png";
import onecode from "../../../assets/images/investment-page/clients/onecode.png";

import globe_lines from "../../../assets/images/common/globe_lines.svg";

import logo1 from "../../../assets/images/investment-page/our-portfolio/Logo1.png";
import logo3 from "../../../assets/images/investment-page/our-portfolio/Logo3.png";
import logo8 from "../../../assets/images/investment-page/our-portfolio/Logo8.png";
import logo9 from "../../../assets/images/investment-page/our-portfolio/Logo9.png";
import logo10 from "../../../assets/images/investment-page/our-portfolio/Logo10.png";
import logo11 from "../../../assets/images/investment-page/our-portfolio/Logo11.png";
import logo12 from "../../../assets/images/investment-page/our-portfolio/Logo12.png";
import logo14 from "../../../assets/images/investment-page/our-portfolio/Logo14.png";
import logo15 from "../../../assets/images/investment-page/our-portfolio/Logo15.png";
import logo16 from "../../../assets/images/investment-page/our-portfolio/Logo16.png";
import logo17 from "../../../assets/images/investment-page/our-portfolio/Logo17.png";
import logo18 from "../../../assets/images/investment-page/our-portfolio/Logo18.png";
import logo19 from "../../../assets/images/investment-page/our-portfolio/Logo19.png";
import logo20 from "../../../assets/images/investment-page/our-portfolio/Logo20.png";
import logo21 from "../../../assets/images/investment-page/our-portfolio/Logo21.png";
import logo22 from "../../../assets/images/investment-page/our-portfolio/Logo22.png";
import logo23 from "../../../assets/images/investment-page/our-portfolio/Logo23.png";
import logo24 from "../../../assets/images/investment-page/our-portfolio/Logo24.png";
import logo25 from "../../../assets/images/investment-page/our-portfolio/Logo25.png";
import logo26 from "../../../assets/images/investment-page/our-portfolio/Logo26.png";
import logo27 from "../../../assets/images/investment-page/our-portfolio/Logo27.png";
import logo28 from "../../../assets/images/investment-page/our-portfolio/Logo28.png";
import logo29 from "../../../assets/images/investment-page/our-portfolio/Logo29.png";
import logo30 from "../../../assets/images/investment-page/our-portfolio/Logo30.png";
import logo31 from "../../../assets/images/investment-page/our-portfolio/Logo31.png";
import logo32 from "../../../assets/images/investment-page/our-portfolio/Logo32.png";
import logo34 from "../../../assets/images/investment-page/our-portfolio/Logo34.png";
import logo35 from "../../../assets/images/investment-page/our-portfolio/Logo35.png";
import logo36 from "../../../assets/images/investment-page/our-portfolio/Logo36.png";
import logo37 from "../../../assets/images/investment-page/our-portfolio/Logo37.png";
import logo38 from "../../../assets/images/investment-page/our-portfolio/Logo38.png";
import logo39 from "../../../assets/images/investment-page/our-portfolio/Logo39.png";
import logo40 from "../../../assets/images/investment-page/our-portfolio/Logo40.png";
import logo41 from "../../../assets/images/investment-page/our-portfolio/Logo41.png";
import logo42 from "../../../assets/images/investment-page/our-portfolio/Logo42.png";
import logo43 from "../../../assets/images/investment-page/our-portfolio/Logo43.png";
import logo44 from "../../../assets/images/investment-page/our-portfolio/Logo44.png";
import logo45 from "../../../assets/images/investment-page/our-portfolio/Logo45.png";
import logo46 from "../../../assets/images/investment-page/our-portfolio/Logo46.png";
import logo47 from "../../../assets/images/investment-page/our-portfolio/Logo47.png";
import logo48 from "../../../assets/images/investment-page/our-portfolio/Logo48.png";
import logo49 from "../../../assets/images/investment-page/our-portfolio/Logo49.png";
import logo50 from "../../../assets/images/investment-page/our-portfolio/Logo50.png";
import logo51 from "../../../assets/images/investment-page/our-portfolio/Logo51.png";
import logo52 from "../../../assets/images/investment-page/our-portfolio/Logo52.png";
import logo53 from "../../../assets/images/investment-page/our-portfolio/Logo53.png";
import logo54 from "../../../assets/images/investment-page/our-portfolio/Logo54.png";
import logo55 from "../../../assets/images/investment-page/our-portfolio/Zomato.png";
import logo56 from "../../../assets/images/investment-page/our-portfolio/Logo56.png";
// import logo57 from "../../../assets/images/investment-page/our-portfolio/logo57.png";
import logo58 from "../../../assets/images/investment-page/our-portfolio/Logo55.png";
import unlu from "../../../assets/images/investment-page/our-portfolio/Logo57Unlu.png";

const portfolio_cards_with_image_array = [
  {
    title: "Dukaan",
    size: "lg",
    image: logoImage3,
    year: "2019",
    catagory: "Ecommerce",
    description: "Digitizing offline merchants",
    tag: "First Cheque",
    industry: "SME_Digitalization",
  },
  {
    title: "One Code",
    size: "lg",
    image: onecode,
    year: "2020",
    catagory: "SAAS/Enterprise/B2B",
    description: "A new-age reseller platform",
    tag: "First Cheque",
    industry: "SAAS_PAAS",
  },
  {
    title: "Krishify",
    size: "lg",
    image: logoImage1,
    year: "2019",
    catagory: "Consumer",
    description: "Social media platform for farmers",
    tag: "First Cheque",
    industry: "Consumer",
  },

  {
    title: "Fitso",
    size: "md",
    image: logoImage4,
    year: "2019",
    catagory: "SAAS/Enterprise/B2B",
    description: "Full Stack Sports Platform",
    tag: "zomato",
    industry: "SAAS_PAAS",
  },
  {
    title: "TryndBuy",
    size: "lg",
    image: logoImage2,
    year: "2019",
    catagory: "DeepTech/IOT/AI",
    description: "Virtual Trial Rooms",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    title: "OneImpression",
    size: "md",
    image: logoImage5,
    year: "2019",
    catagory: "SAAS/Enterprise/B2B",
    description: "Influencer Marketing Platform ",
    tag: "",
    industry: "SAAS_PAAS",
  },
];

const portfolio_cards_with_logos_array = [
  {
    image: logo53,
    text: "SpaceTech for small Satellite market",
    url: "https://agnikul.in/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo15,
    text: "App Intelligence & Growth Platform",
    url: "https://www.apxor.com/",
    tag: "",
    industry: "SAAS_PAAS",
  },
  {
    image: logo1,
    text: "An online code editor",
    url: "https://www.cloudworx.ai/",
    tag: "First Cheque",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo8,
    text: "Experiential Holiday Tours",
    url: "https://www.deyorcamps.com/",
    tag: "",
    industry: "E-commerce",
  },
  {
    image: logo39,
    text: "Digitizing offline merchants ",
    url: "https://mydukaan.io/",
    tag: "First Cheque",
    industry: "SME_Digitalization",
  },
  {
    image: logo56,
    text: "AI-powered restaurant technology",
    url: "https://easyeat.ai/",
    tag: "",
    industry: "SME_Digitalization",
  },
  {
    image: logo48,
    text: "Health & beauty social commerce",
    url: "https://www.eloelo.in/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo16,
    text: "Cross-border commerce platform ",
    url: "https://eunimart.com/",
    tag: "",
    industry: "",
    industry: "SME_Digitalization",
  },
  {
    image: logo36,
    text: "Member-based angel network",
    url: "http://www.faad.in/",
    tag: "First Cheque",
    industry: "VentureCapital",
  },
  {
    image: logo26,
    text: "Education loan platform",
    url: "https://www.financepeer.com/",
    tag: "",
    industry: "Fintech",
  },

  {
    image: logo51,
    text: "Full Stack Sports Platform",
    url: "https://www.getfitso.com/",
    tag: "zomato",
    industry: "Consumer",
  },
  {
    image: logo46,
    text: "AI-enabled video publisher network ",
    url: "https://www.flickstree.com/",
    tag: "",
    industry: "SAAS_PAAS",
  },
  {
    image: logo42,
    text: "Design & Development Studio",
    url: "https://grappus.com/",
    tag: "",
    industry: "SAAS_PAAS",
  },
  {
    image: logo25,
    text: "Service provider for hiring help",
    url: "https://housmaid.com/",
    tag: "",
    industry: "Consumer",
  },
  {
    image: logo37,
    text: "Drone data solutions company ",
    url: "https://huviair.com/",
    tag: "",
    industry: "DeepTech_IOT_AI ",
  },
  {
    image: logo45,
    text: "Business Networking Portal",
    url: "http://www.justbusinesses.net/",
    tag: "",
    industry: "SME_Digitalization",
  },
  {
    image: logo41,
    text: "Interactive story-telling platform",
    url: "https://kahanibox.com/",
    tag: "",
    industry: "Consumer",
  },
  {
    image: logo22,
    text: "Marketplace for freelancers ",
    url: "https://kiwi-market.com/",
    tag: "First Cheque",
    industry: "E-commerce",
  },
  {
    image: logo12,
    text: "City Lifesyle & News Platform ",
    url: "https://www.knocksense.com/",
    tag: "",
    industry: "Consumer",
  },
  {
    image: logo47,
    text: "Social Media platform for farmers",
    url: "https://www.krishify.com/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo29,
    text: "Professional network for women",
    url: "https://www.leap.club/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo11,
    text: "Micro Loans for SMEs",
    url: "https://www.loansimple.in/",
    tag: "shutdown",
    industry: "Fintech",
  },
  {
    image: logo49,
    text: "Social platform for neighbourhood",
    url: "https://localiti.io/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo34,
    text: "Chatbot store",
    url: "https://www.messengerx.io/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo14,
    text: "Second-hand phone marketplace",
    url: "https://www.mobigarage.com/",
    tag: "",
    industry: "E-commerce",
  },
  {
    image: logo31,
    text: "Community Lifetsyle Brand for mothers",
    url: "https://motherlystore.com/",
    tag: "",
    industry: "Consumer",
  },
  {
    image: logo38,
    text: "Co-working space",
    url: "https://mysoho.co/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo23,
    text: "Ayurveda healthcare ecosystem",
    url: "https://nirogstreet.com/",
    tag: "",
    industry: "E-commerce",
  },
  {
    image: logo52,
    text: "A new-age reseller platform",
    url: "https://www.onecode.in/",
    tag: "First Cheque",
    industry: "SAAS_PAAS",
  },
  {
    image: logo50,
    text: "Influencer Marketing Platform ",
    url: "https://oneimpression.io/",
    tag: "",
    industry: "SAAS_PAAS",
  },
  {
    image: logo30,
    text: "Women's community to know, glow & grow",
    url: "https://www.pankhuri.co/",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo9,
    text: "Virtual ATM ",
    url: "http://mypaymart.com/",
    tag: "",
    industry: "Fintech",
  },
  {
    image: logo10,
    text: "Personal wealth management for couples",
    url: "https://www.piggy.co.in/",
    tag: "",
    industry: "Fintech",
  },
  {
    image: logo20,
    text: "SEO Automation",
    url: "https://rankz.io/",
    tag: "First Cheque",
    industry: "SAAS_PAAS",
  },
  {
    image: logo21,
    text: "Merchandise designing & printing ",
    url: "https://www.redesyn.com/",
    tag: "",
    industry: "E-commerce",
  },
  {
    image: logo3,
    text: "Conversational AI platform for enterprises",
    url: "http://rezo.ai/",
    tag: "First Cheque",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo24,
    text: "Wedding Planning platform",
    url: "https://www.shaadisaga.com/",
    tag: "shaadi",
    industry: "E-commerce",
  },
  {
    image: logo28,
    text: "Cricket bat sensor",
    url: "https://www.stancebeam.com/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo44,
    text: "Online store & analytics for fashion labels ",
    url: "https://6degree.co/",
    tag: "",
    industry: "SME_Digitalization",
  },
  {
    image: logo35,
    text: "D2C Gourmet Tea Brand ",
    url: "https://teatrunk.in/",
    tag: "",
    industry: "Consumer",
  },
  {
    image: logo19,
    text: "Affordable co-working space",
    url: "https://www.theofficepass.com/",
    tag: "",
    industry: "Consumer ",
  },
  {
    image: logo40,
    text: "Virtual Trial Rooms ",
    url: "https://tryndbuy.com/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: unlu,
    text: "Celebrity engagement & masterclass for India",
    url: "https://unlu.io/",
    tag: "",
    industry: "Consumer ",
  },
  {
    image: logo43,
    text: "Early Stage Micro VC",
    url: "http://veda.vc/",
    tag: "",
    industry: "VentureCapital ",
  },
  {
    image: logo54,
    text: "AI-driven voice automation platform ",
    url: "http://vernacular.ai/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo27,
    text: "E-learning platform for state boards",
    url: "https://www.vidyakul.com/",
    tag: "",
    industry: "Consumer",
  },
  // {
  //   image: logo58,
  //   text: "Online vocational learning platform",
  //   url: "https://weskill.co.in/",
  //   tag: "First Cheque",
  //   industry: "Consumer",
  // },
  {
    image: logo17,
    text: "AI-powered process compliance",
    url: "https://wobot.ai/",
    tag: "",
    industry: "DeepTech_IOT_AI",
  },
  {
    image: logo32,
    text: "Infographic Sharing Platform",
    url: "https://www.yodaapp.ai/feed",
    tag: "First Cheque",
    industry: "Consumer",
  },
  {
    image: logo18,
    text: "New-era retail solution",
    url: "https://www.zaroor.app/",
    tag: "First Cheque",
    industry: "SME_Digitalization",
  },
  {
    image: logo55,
    text: "Restaurant aggregator and food delivery company",
    url: "https://www.zomato.com/",
    tag: "",
    industry: "",
  },
];
const nextactive = (event, elementclass) => {
  var tagbutton = document.getElementsByClassName("tagbutton");
  for (var i = 0; i < tagbutton.length; i++) {
    tagbutton[i].classList.remove("active");
    if (tagbutton[i].classList[1] == elementclass) {
      tagbutton[i].classList.add("active");
    }
  }
  var txtclass1Elements = document.getElementsByClassName("element-wrapper");
  for (var i = 0; i < txtclass1Elements.length; i++) {
    txtclass1Elements[i].style.display = "none";
    if (txtclass1Elements[i].classList[1] == elementclass) {
      txtclass1Elements[i].style.display = "block";
    }
    if (elementclass == "all") {
      txtclass1Elements[i].style.display = "block";
    }
  }
};
const OurPortfolio = (props) => {
  const media = window.matchMedia(`(min-width: 768px)`);

  let sectionDetails = (
    <div className="sectionDetails">
      <h5 className="text-center">OUR PORTFOLIO</h5>
      <h1 className="text-center">The Gamechangers</h1>
      <p className="text-center">
        Helping startups{" "}
        <span className="font-weight-bold">
          scale at crazy speeds, break down barriers, disrupt industries, change
          the world.
        </span>
      </p>
      <div className="tabButtonSec">
        <div
          className="tagbutton all active"
          {...nextactive}
          onClick={() => {
            nextactive(null, "all");
          }}
        >
          All
        </div>
        <div
          className="tagbutton Consumer"
          {...nextactive}
          onClick={() => {
            nextactive(null, "Consumer");
          }}
        >
          Consumer
        </div>
        <div
          className="tagbutton DeepTech_IOT_AI"
          {...nextactive}
          onClick={() => {
            nextactive(null, "DeepTech_IOT_AI");
          }}
        >
          Deeptech/IOT/AI
        </div>
        <div
          className="tagbutton Fintech"
          {...nextactive}
          onClick={() => {
            nextactive(null, "Fintech");
          }}
        >
          Fintech
        </div>
        <div
          className="tagbutton E-commerce"
          {...nextactive}
          onClick={() => {
            nextactive(null, "E-commerce");
          }}
        >
          Marketplace/Commerce
        </div>
        <div
          className="tagbutton SAAS_PAAS"
          {...nextactive}
          onClick={() => {
            nextactive(null, "SAAS_PAAS");
          }}
        >
          SAAS/Enterprise/B2B
        </div>

        <div
          className="tagbutton SME_Digitalization"
          {...nextactive}
          onClick={() => {
            nextactive(null, "SME_Digitalization");
          }}
        >
          SME Digitalization
        </div>

        <div
          className="tagbutton VentureCapital"
          {...nextactive}
          onClick={() => {
            nextactive(null, "VentureCapital");
          }}
        >
          Venture Capital
        </div>
      </div>
    </div>
  );

  let portfolio_cards_with_image = [];
  portfolio_cards_with_image_array.forEach((element, index) => {
    portfolio_cards_with_image.push(
      <div
        key={`ourPortfolio-withImage-card-${index}`}
        className={`element-wrapper ${element.industry}`}
      >
        <div
          className="element"
          style={{ backgroundImage: "url(" + element.image + ")" }}
        >
          {element.tag != "" ? (
            <>
              <div class="ribbon ribbon-top-left ribbon-large">
                {element.tag == "First Cheque" ? (
                  <div className="firstcheck ribbon-tag">1st Cheque</div>
                ) : null}
                {element.tag == "zomato" ? (
                  <div className="zomoto ribbon-tag">Acquired by Zomato</div>
                ) : null}
              </div>
            </>
          ) : null}
          <div className="front-content">
            <div className={`left ${element.size}`}>{element.title}</div>
            <div className="right">
              <div className="text">Invested in</div>
              <div className="year font-weight-bold">{element.year}</div>
            </div>
          </div>
          <div className="hover-content">
            <div className="catagory">{element.catagory}</div>
            <div className="description">{element.description}</div>
          </div>
        </div>
      </div>
    );
  });

  let portfolio_cards_with_logos = [];
  portfolio_cards_with_logos_array.forEach((element, index) => {
    portfolio_cards_with_logos.push(
      <div
        key={`ourPortfolio-withLogo-card-${index}`}
        className={`element-wrapper ${element.industry}`}
      >
        <div className="element">
          {element.tag != "" ? (
            <>
              <div class="ribbon ribbon-top-left">
                {element.tag == "First Cheque" ? (
                  <div className="firstcheck ribbon-tag">1st Cheque</div>
                ) : null}
                {element.tag == "shutdown" ? (
                  <div className="shutdown ribbon-tag">Shutdown</div>
                ) : null}
                {element.tag == "zomato" ? (
                  <div className="zomoto ribbon-tag">Acquired by Zomato</div>
                ) : null}
                {element.tag == "shaadi" ? (
                  <div className="zomoto ribbon-tag">Acquired</div>
                ) : null}
              </div>
            </>
          ) : null}
          <div className="front-content d-flex">
            <img src={element.image} alt="" alt="image icon" />
          </div>
          <div className="hover-content">
            <div className="title-text">{element.text}</div>
            <div className="subText">
              <a href={element.url} target="_blank">
                <img src={globe_lines} alt="" alt="image globe lines" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  });

  const flickityOptions = {
    pageDots: false,
    prevNextButtons: false,
    cellAlign: "left",
    contain: true,
    wrapAround: true,
    // autoPlay: 6000
  };

  return (
    <div className="container-fluid ourPortfolio-root">
      <div className="container">
        {sectionDetails}
        <div className="portfolio-cards-with-image row justify-content-center large-card">
          {portfolio_cards_with_image}
        </div>
        <div className="portfolio-cards-with-logos row justify-content-center small-cards">
          {portfolio_cards_with_logos}
        </div>
      </div>
    </div>
  );
};

export default OurPortfolio;
