import React, { useState } from "react";
import "./styles.scss";
import axios from "axios";
import PrimeLogo from "../../assets/icons/appyprime.svg";
import { STRAPI_BASE_URL } from "../../utils/common";
import { useLocation } from "react-router-dom";

const DevForm = (props) => {
  // Define state variables for input fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [appName, setAppName] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const [toastMsg, setToastMsg] = useState(null);

  const location = useLocation();
  const isAffiliateForm = location.pathname === "/affiliate";

  const resetform = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setCompany("");
    setWebsite("");
    setAppName("");
    setPhone("");
    setFile(null);
    const fileInfo = document.getElementById("inputFile");
    // @ts-ignore
    if (fileInfo.value) document.getElementById("inputFile").value = null;
  };

  const formData = new FormData();
  formData.append("file", file);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    const data = new FormData();
    data.append("firstName", firstName);
    data.append("lastName", lastName);
    data.append("email", email);
    data.append("companyName", company);
    data.append("website", website);
    data.append("appNames", appName);
    data.append("phoneNumber", phone);

    let attachmentValue = null;

    // Check if a file is selected
    if (file) {
      data.append("files", file);

      const response = await axios.post(`${STRAPI_BASE_URL}/api/upload/`, data);
      attachmentValue = response.data[0].id;
    }

    axios
      .post(
        `${STRAPI_BASE_URL}/api/${
          isAffiliateForm
            ? "appyhigh-affiliate-forms"
            : "appyhigh-developer-forms"
        }`,
        {
          data: {
            firstName: firstName,
            lastName: lastName,
            email: email,
            companyName: company,
            website: website,
            appNames: appName,
            attachment: attachmentValue,
            phoneNumber: phone, // Set the attachment value conditionally
          },
        }
      )
      .then(({ data }) => {
        fireToast("success");
        resetform();
      })
      .catch((error) => {
        setToastMsg(
          "Oops! Something went wrong. Please try again later or contact us directly."
        );
        fireToast("error");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const fireToast = (type) => {
    if (type === "success") {
      setToastMsg("Thanks for getting in touch! We'll be in contact soon. 👋");
    } else if (type === "error") {
      setToastMsg(
        "Oops! Something went wrong. Please try again later or contact us directly."
      );
    } else {
      setToastMsg(
        "Oops! Something went wrong. Please try again later or contact us directly."
      );
    }

    var x = document.getElementById("snackbar");
    x.className =
      x.className + `${type === "success" ? "succmessage" : "errmessage"} show`;
    setTimeout(function () {
      x.className = x.className.replace("show", "");
    }, 5000);
  };

  return (
    <main className="top-banner-contact hero" id="hero">
      <section className="form-container">
        <div className="left-container">
          <div className="outerlogo">
            <h1 href="#">
              <img height="70" src={PrimeLogo} alt="appyhigh-prime-logo" />
            </h1>
            {isAffiliateForm ? (
              <>
                <h4 id="tagline">
                  High Quality <span className="purple">Apps.</span>
                  <br />
                  <br />
                  High Transaction <span className="purple">Frequency.</span>
                  <br />
                  <br />
                  Reliable <span className="purple">Payouts.</span>
                </h4>
              </>
            ) : (
              <h4 id="tagline">
                Reach <span className="purple">Premium Users</span>
                <br />
                <br />
                Get <span className="purple">Better Visibility.</span>
                <br />
                <br />
                Add your app to <span className="purple">AppyHigh Prime.</span>
              </h4>
            )}
          </div>
          {isAffiliateForm ? (
            <div className="form-subhead">
              <h5 className="appyhighprimeques">EARN WITH APPYHIGH PRIME</h5>
              <ol className="primelist">
                <li>High Quality Premium Apps</li>
                <li>Top Performing apps- High purchase frequency</li>
                <li>Reliable Payouts</li>
                <li>Easy to Join</li>
                <li>Zero Investment</li>
                <li>Accurate Tracking</li>
                <li>Customer support</li>
              </ol>
            </div>
          ) : (
            <div className="form-subhead">
              <h5 className="appyhighprimeques">
                WHAT YOU GET WITH APPYHIGH PRIME
              </h5>
              <ol className="primelist">
                <li>Enhance Your App's Visibility </li>
                <li>Maximise ROI </li>
                <li>Explore New Distribution Channel – AppyHigh Web </li>
                <li>Gain Greater Control and Transparency </li>
                <li>Enjoy Dedicated Customer Support</li>
              </ol>
            </div>
          )}
        </div>
        <div className="inner-container">
          <div className="form-head">
            <h2>Get in touch with us! &#128400;</h2>
          </div>
          <form className="form" onSubmit={handleSubmit}>
            {/* First Name */}

            <div className="singleRow">
              <div className="input-container ic1 fInput">
                <input
                  id="firstname"
                  className="input"
                  type="text"
                  placeholder=" "
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <div className="cut"></div>
                <label htmlFor="firstname" className="placeholder">
                  First Name*
                </label>
              </div>

              {/* Last Name */}
              <div className="input-container ic1">
                <input
                  id="lastname"
                  className="input"
                  type="text"
                  placeholder=" "
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <div className="cut"></div>
                <label htmlFor="lastname" className="placeholder">
                  Last Name*
                </label>
              </div>
            </div>
            <div className="singleRow">
              {/* Email */}
              <div className="input-container ic1 fInput">
                <input
                  id="email"
                  className="input"
                  type="email"
                  placeholder=" "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="cut"></div>
                <label htmlFor="email" className="placeholder">
                  Email*
                </label>
              </div>

              {/* Phone number */}
              <div className="input-container ic1">
                <input
                  id="phone"
                  className="input"
                  type="text"
                  placeholder=" "
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
                <div className="cut"></div>
                <label htmlFor="phone" className="placeholder">
                  Phone
                </label>
              </div>
            </div>

            {/* Company Name */}
            <div className="input-container ic1">
              <input
                id="company"
                className="input"
                type="text"
                placeholder=" "
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                required
              />
              <div className="cut"></div>
              <label htmlFor="company" className="placeholder">
                Company Name*
              </label>
            </div>

            {/* Website */}
            <div className="input-container ic1">
              <input
                id="website"
                className="input"
                type="text"
                placeholder=" "
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
              <div className="cut"></div>
              <label htmlFor="website" className="placeholder">
                Website
              </label>
            </div>

            {/* Apps Name */}
            <div className="input-container ic1">
              <input
                id="appname"
                className="appLink input"
                type="text"
                placeholder=" "
                value={appName}
                onChange={(e) => setAppName(e.target.value)}
              />
              <div className="cut"></div>
              <label htmlFor="appname" className="placeholder largeScreen">
                Multiple app links/names (Separated by comma)
              </label>
              <label htmlFor="appname" className="placeholder smallScreen">
                Multiple app links (Separated by ,)
              </label>
            </div>

            {/* File Input */}
            <div className="file-input">
              <label htmlFor="appname" className="file-label">
                Reference Document (Optional):
              </label>
              <input
                id="inputFile"
                type="file"
                className="inputfile"
                placeholder=" "
                onChange={(e) => setFile(e.target.files[0])}
              />
            </div>

            <button className="submitbtn" type="submit">
              <span className="submitTxt">
                {isSubmitting ? "Submitting" : "Submit"}
              </span>
            </button>

            <div className="snackbar-container">
              <div id="snackbar">{toastMsg}</div>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
};

export default DevForm;
