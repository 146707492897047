import React from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import DevForm from "../../components/form";
function Form() {
  return (
    <>
      <div className="form-root">
        <Navbar theme="theme-light" />

        <div style={{ height: "max-content", background: "black" }}>
          <DevForm />
        </div>

        <section>
          <Footer pagename={"Careers"} />
        </section>
      </div>
    </>
  );
}

export default Form;
