import { DEPLOYMENT_ENVIRONMENTS } from "./contants";

export const deploymentEnvironment =
  process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT;

export const isDevEnvironment =
  deploymentEnvironment === DEPLOYMENT_ENVIRONMENTS.DEV;

export const STRAPI_BASE_URL = isDevEnvironment
  ? // "https://strapi-dev.apyhi.com"
    "https://strapi.apyhi.com"
  : "https://strapi.apyhi.com";
